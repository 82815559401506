<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-dept
              :editable="editable"
              label="관리부서"
              name="deptCd"
              v-model="searchParam.deptCd"
              :isFirstValue="false"
            >
            </c-dept>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-func-location 
              type="search" 
              :plantCd="searchParam.plantCd" 
              :editable="editable" 
              label="기능위치" 
              name="funcLocationCd" 
              v-model="searchParam.funcLocationCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-equip-class
              :editable="editable"
              label="설비유형"
              name="equipmentTypeCd"
              v-model="searchParam.equipmentTypeCd">
            </c-equip-class>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-12">
          <c-table
            ref="table"
            title="설비 가용성 분석 목록"
            tableId="table"
            :merge="grid.merge"
            :filtering="false"
            :columns="grid.columns"
            :data="grid.data"
            @linkClick="linkClick"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="검색" icon="search" @btnClicked="getList" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>  
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'work-order-publish-graph',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        funcLocationCd: '',
        equipmentName: '',
        equipmentTypeCd: '',
        grade: null,
        discardFlag: null,
      },
      grid: {
        columns: [
          {
            name: 'plant_name',
            field: 'plant_name',
            label: '사업장',
            align: 'center',
            fix: true,
            style: 'width: 120px',
          },
          {
            name: 'equipment_type_name',
            field: 'equipment_type_name',
            label: '설비유형',
            align: 'center',
            fix: true,
            style: 'width: 150px',
          },
          {
            name: 'equipment_no',
            field: 'equipment_no',
            label: '설비관리번호',
            align: 'center',
            fix: true,
            style: 'width: 150px',
          },
          {
            name: 'equipment_name',
            field: 'equipment_name',
            label: '설비명',
            align: 'center',
            fix: true,
            style: 'width:200px',
            type: 'link'
          },
          {
            name: 'mttr',
            field: 'mttr',
            label: 'MTTR(시간)',
            align: 'right',
            style: 'width:90px',
            helpcomment: '수리하는 평균 시간',
          },
          {
            name: 'mttf',
            field: 'mttf',
            label: 'MTTF(시간)',
            align: 'right',
            style: 'width:90px',
            helpcomment: '수리 후 고장까지의 평균시간',
          },
          {
            name: 'mtbf',
            field: 'mtbf',
            label: 'MTBF(시간)',
            align: 'right',
            style: 'width:90px',
            helpcomment: '평균 고장 간격<br>MTTR + MTTF',
          },
          // [MTTF/MTBF]
          {
            name: 'availability_str',
            field: 'availability_str',
            label: 'Availability',
            align: 'right',
            style: 'width:90px',
            helpcomment: 'MTTF / MTBF * 100',
          },
          {
            name: 'lapse_time',
            field: 'lapse_time',
            label: '부하<br>시간',
            align: 'right',
            style: 'width:60px',
            helpcomment: '설비가 총 가동해야 하는 시간<br>(정지시간 포함)',
          },
          {
            name: 'fix_count',
            field: 'fix_count',
            label: '고장(건)',
            align: 'right',
            style: 'width:60px',
          },
          {
            name: 'breakdown_times',
            field: 'breakdown_times',
            label: '고장정지<br>시간(합계)',
            align: 'right',
            style: 'width:70px',
          },
          // [고장건수/총가동시간]
          {
            label: '고장율',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'fix_percent_strength',
                field: 'fix_percent_strength',
                label: '강도율',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
                helpcomment: '총 고장정지시간 / 부하시간 * 100',
              },
              {
                name: 'fix_percent_frequency',
                field: 'fix_percent_frequency',
                label: '도수율',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
                helpcomment: '고장건수 / 부하시간 * 100',
              },
            ]
          },
          {
            name: 'breakdown_date',
            field: 'breakdown_date',
            label: '최근고장일',
            align: 'center',
            style: 'width:120px',
          },
          {
            name: 'management_dept_name',
            field: 'management_dept_name',
            label: '관리부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'func_location_name',
            field: 'func_location_name',
            label: '기능위치',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      period: [],
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // 년도 설정
      this.listUrl = selectConfig.sts.wha.mttrmtbf.url;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-chart
  padding: 5px !important
.widget-chart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>

<style lang="scss">
.tooltip-title {
  width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  border-radius: 5px;
  background: #757474;
  color: #fff;
  margin: 2px;
}
.tooltip-layer {
  background-color: #fff;
  color: #504f4f;
  padding: 5px;
}
.tooltip-content {
  padding-left: 5px;
  display: inline-block;
  line-height: 24px;
  font-weight: 600;
}
.tooltip-date {
  width: 100%;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
  font-size: 1.1em;
}
</style>